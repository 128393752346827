import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import { TextGroup } from "components/common"
import { device } from "utils"

import centerBlob from "src/images/blob/comingsoon_middleBlob.svg"
// import wineLoading from "src/images/video/loading_bottle.mp4"
// import Spinner from "../common/loaders/Spinner"
import logo from "src/images/nav/navLogo_light.svg"

const DonationRequestDisabled = () => {
  const { donation_requests_disabled } = useStaticQuery(query)

  return (
    <StyledDonationRequestDisabled>
      <div className="centerWrap">
        <div className="text">
          <div className="logoWrap">
            <img src={logo} alt="" className="logo" loading="eager" />
          </div>
          <TextGroup {...donation_requests_disabled} titleAs="h3" />

          {/* <div className="back-wrap">
            <Link to="/nonprofit" className="back">
              <span className="arrow">←</span> Back to Dashboard
            </Link>
          </div> */}
        </div>
        <img
          className="center-blob"
          src={centerBlob}
          alt="blob"
          loading="eager"
        />
      </div>
    </StyledDonationRequestDisabled>
  )
}

const StyledDonationRequestDisabled = styled.section`
  position: relative;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  padding-bottom: var(--sp-48);

  .logoWrap {
    margin: 0 auto;
    padding-top: 2.5vh;
  }

  .centerWrap {
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;

    .text {
      text-align: center;
      position: absolute;
      z-index: 3;
      padding: 0 var(--sp-24);

      @media ${device.laptop} {
        padding: 0;
      }

      h3 {
        margin: 0 auto;
        margin-top: var(--sp-16);
        margin-bottom: var(--sp-16);
        max-width: 500px;

        @media ${device.desktop} {
          max-width: 700px;
        }
      }

      p {
        margin: 0 auto;
        max-width: 550px;
      }

      .videoWrap {
        width: 50px;
        margin: 0 auto;
      }
    }

    .center-blob {
      width: 100%;
      max-width: 900px;
      display: none;

      @media ${device.laptop} {
        display: inline-block;
      }
    }

    .back-wrap {
      margin-top: var(--sp-24);
      text-align: center;

      .back {
        font-size: 1.1rem;
        font-weight: bold;
        line-height: 1;

        .arrow {
          line-height: 1;
          font-size: 1.75rem;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .blob {
    position: absolute;
    z-index: 1;
    display: none;

    @media ${device.laptop} {
      display: inline-block;
    }

    &.center {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.left {
      bottom: 0;
      left: 0;
    }
  }
`

export const query = graphql`
  {
    donation_requests_disabled: contentfulSection(
      sectionId: { eq: "donation_requests_disabled" }
    ) {
      ...Section
    }
  }
`

export default DonationRequestDisabled
