import React from "react"

import "src/styles/reset.css"
import "src/styles/fonts/font-family.css"
import "src/styles/theme.css"
import "src/styles/layout.css"
import "src/styles/typography.css"
import { useMeta } from "src/utils"

import { Seo } from "components/common"
import { graphql } from "gatsby"
import DonationRequestDisabled from "src/components/nonprofit/DonationRequestsDisabled"

export default function HomePage({ data: { home } }) {
  const meta = useMeta(home)

  return (
    <>
      <Seo {...meta} />
      <DonationRequestDisabled />
    </>
  )
}

export const query = graphql`
  {
    home: contentfulPage(pageId: { eq: "home" }) {
      ...Page
    }
  }
`
